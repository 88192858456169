import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';

import Logo from "./Logo";
import { DropdownMenu } from "./DropdownMenu";
import LanguageSwitch from "./LanguageSwitch";
import { useClickOutside } from "../lib/hooks";
import { JeanneauIcon, MenuIcon, PrestigeIcon, CloseIcon, ExcessIcon } from "./Icons";

import * as fbq from '../lib/fpixel'

// Yay! Estudio
import { cachedRequest } from "../lib/cached-requests";

const NavBar = ({ initialColor = 'white', data }) => {
	const router = useRouter();
	const [dropdownData, setDropdownData] = useState();
	const [navBackground, setNavBackground] = useState(false);
	const navRef = useRef();
	navRef.current = navBackground;
	const [isMobile, setIsMobile] = useState(false)

	const [show, setShow] = useState(false);
	const showDropdown = (e)=>{
		setShow(!show);
		hoverPrestige(false);
	}
	const hideDropdown = e => {
		setShow(false);
	}


	const [isOpenPrestige, setIsOpenPrestige] = useState(false);
	const [isOpenJeanneau, setIsOpenJeanneau] = useState(false);
	const [isOpenExcess, setIsOpenExcess] = useState(false);
	const hoverPrestige = (instruction) => {
		isOpenJeanneau && setIsOpenJeanneau(false);
		isOpenExcess && setIsOpenExcess(false);
		setIsOpenPrestige(instruction)
	};
	const hoverJeanneau = (instruction) => {
		isOpenPrestige && setIsOpenPrestige(false);
		isOpenExcess && setIsOpenExcess(false);
		setIsOpenJeanneau(instruction)
	};
	const hoverExcess = (instruction) => {
		isOpenPrestige && setIsOpenPrestige(false);
		isOpenJeanneau && setIsOpenJeanneau(false);
		setIsOpenExcess(instruction)
	};
	const togglePrestige = () => {
		isOpenJeanneau && setIsOpenJeanneau(false);
		isOpenExcess && setIsOpenExcess(false);
		setIsOpenPrestige(!isOpenPrestige)
	};
	const toggleJeanneau = () => {
		isOpenPrestige && setIsOpenPrestige(false);
		isOpenExcess && setIsOpenExcess(false);
		setIsOpenJeanneau(!isOpenJeanneau)
	};
	const toggleExcess = () => {
		isOpenPrestige && setIsOpenPrestige(false);
		isOpenJeanneau && setIsOpenJeanneau(false);
		setIsOpenExcess(!isOpenExcess)
	};

	let domNodePrestige = useClickOutside(() => {
		setIsOpenPrestige(false);
	});
	let domNodeJeanneau = useClickOutside(() => {
		setIsOpenJeanneau(false);
	});

	let domNodeExcess = useClickOutside(() => {
		setIsOpenExcess(false);
	});

	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if(!data)
			cachedRequest('GET_MENU_YACHTS', { locale: router.locale }).then((res) => {
				setDropdownData(res.yachts);
			});
		else
			setDropdownData(data)
		if (window.innerWidth < 996) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}


		const handleScroll = () => {
		const show = window.scrollY > 0;
		if (navRef.current !== show) {
			setNavBackground(show);
		}
		};
		document.addEventListener("scroll", handleScroll , {passive: true});
		return () => {
		document.removeEventListener("scroll", handleScroll , {passive: true});
		};

		
	});

  return (
		<>
			

			<Navbar
				expanded={expanded}
				expand="lg"
				variant="light"
			>
			<Container>
				<section className="navbar-wrapper">
					<div className="navbar-brands">

						<div id="logos-disposition" style={{ display: "flex", alignItems: "center"}}>
							<div style={{display: "flex", alignItems: "center"}}>
							<Link href="/">
								<Navbar.Brand>
									<Logo color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"} customClass="img-fluid" alt="Yachting México" />
								</Navbar.Brand>
								
							</Link>
							</div>
						</div>

						<div id="brand-logos" style={{display: "flex"}}>
							<span
								id="link"
								onClick={isMobile ? togglePrestige : () => router.push("/yachts?brand=prestige")}
								onMouseEnter={() => hoverPrestige(true)}
								className={"nav-link "+(router.asPath.includes("prestige") && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								<div className="brand-logo-container">
									<PrestigeIcon color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
								</div>
							</span>

							<span
								id="link"
								onClick={isMobile ? toggleJeanneau : () => router.push("/yachts?brand=jeanneau")}
								onMouseEnter={() => hoverJeanneau(true)}
								className={"nav-link "+(router.asPath.includes("jeanneau") && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								<div className="brand-logo-container">
									<JeanneauIcon color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
								</div>
							</span>

							<span
								id="link"
								onClick={isMobile ? toggleExcess : () => router.push("/yachts?brand=Excess")}
								onMouseEnter={() => hoverExcess(true)}
								className={"nav-link "+(router.asPath.includes("excess") && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								<div className="brand-logo-container">
									<ExcessIcon height='20' color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
								</div>
							</span>

						</div>

						<div className="navbar-buttons">
							
							<Link 
								href="/contact" 
								className="btn btn-primary px-4 text-uppercase mx-lg-3 "
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false)}}
								onClick={() => fbq.event('ViewContent',{content_ids: 'Contact'})}
							>
								{router.locale === "es" ? "Contacto" : "Contact"}
							</Link>
							<LanguageSwitch transparent={!navBackground && !initialColor ? true : false }/>
							
						</div>

						<div className="nav-mobile-container">
							<LanguageSwitch transparent={!navBackground && !initialColor ? true : false }/>
							<div
								onClick={() => setExpanded(expanded ? false : "expanded")}
								className="toggleMenuIcon"
								style={{width: "40px", height: "40px"}}
							>
								<MenuIcon color={initialColor === "white" || navBackground ? "#53575A" : "#fff"} />
							</div>

						</div>
					</div>
					<Navbar id="navbar-nav" className={`navbar-nav ${expanded ? 'show' : ''}`}>
						<div className={`backdrop ${expanded ? 'show' : ''}`} onClick={() => setExpanded(false)} ></div>
						<Nav>
							<Link 
								href="/"
								alt= 'home'
								id="link"
								onClick={() => fbq.event('ViewContent',{content_ids: 'Home'})}
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false); }}
								className={"nav-link "+(router.pathname === "/" && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								{router.locale === "es" ? "Inicio" : "Home"}
							
							</Link>
							
							<Link
								href='/about'
								onClick={() => fbq.event('ViewContent',{content_ids: 'About'})}
								id="link"
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false)}}
								className={"nav-link "+(router.pathname === "/about" && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								{router.locale === "es" ? "Conócenos" : "About us"}
							</Link>
							
							<Link
								href='/allies'
								onClick={() => fbq.event('ViewContent',{content_ids: 'Allies'})}
								id="link"
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false)}}
								className={"nav-link "+(router.pathname === "/allies" && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								{router.locale === "es" ? "Aliados" : "Allies"}
							</Link>
							
							<Link 
								href="/news" 
								onClick={() => fbq.event('ViewContent',{content_ids: 'News'})}
								id="link"
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false)}}
								className={"nav-link "+(router.pathname === "/news" && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								{router.locale === "es" ? "Noticias" : "News"}
							
							</Link>

							
							<Link
								href='/fractional'
								onClick={() => fbq.event('ViewContent',{content_ids: 'Fractional'})}
								id="link"
								onMouseEnter={() => {hoverPrestige(false); hoverJeanneau(false)}}
								className={"nav-link "+(router.pathname === "/fractional" && "active")+((!navBackground && !initialColor) && " nav-top")}
							>
								Fractional
							</Link>

							<div id="brand-logos-movil" style={{display: "none"}}>
								<span
									id="link"
									onClick={isMobile ? togglePrestige : () => router.push("/yachts?brand=prestige")}
									onMouseEnter={() => hoverPrestige(true)}
									className={"nav-link "+(router.asPath.includes("prestige") && "active")+((!navBackground && !initialColor) && " nav-top")}
								>
									<div className="brand-logo-container">
										<PrestigeIcon color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
									</div>
								</span>

								<span
									id="link"
									onClick={isMobile ? toggleJeanneau : () => router.push("/yachts?brand=jeanneau")}
									onMouseEnter={() => hoverJeanneau(true)}
									className={"nav-link "+(router.asPath.includes("jeanneau") && "active")+((!navBackground && !initialColor) && " nav-top")}
								>
									<div className="brand-logo-container">
										<JeanneauIcon color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
									</div>
								</span>

								<span
									id="link"
									onClick={isMobile ? toggleExcess : () => router.push("/yachts?brand=Excess")}
									onMouseEnter={() => hoverExcess(true)}
									className={"nav-link "+(router.asPath.includes("excess") && "active")+((!navBackground && !initialColor) && " nav-top")}
								>
									<div className="brand-logo-container">
										<ExcessIcon height='20' color={(initialColor === "white" || navBackground ) ? "#53575A" : "#fff"}/>
									</div>
								</span>

							</div>

							
						</Nav>

					</Navbar>		
				</section>
			</Container>
			</Navbar>

			{dropdownData ?
				<React.Fragment>
					<DropdownMenu
						closeNavbar={setExpanded}
						close={togglePrestige}
						state={isOpenPrestige}
						content={dropdownData.filter((yacht) => {
							return yacht.brand != null && yacht.brand.slug === "prestige";
						})}
						ref={domNodePrestige}
						onMouseLeave={() => hoverPrestige(false)}
						/>
					<DropdownMenu
						closeNavbar={setExpanded}
						close={toggleJeanneau}
						state={isOpenJeanneau}
						content={dropdownData.filter((yacht) => {
							return yacht.brand != null && yacht.brand.slug === "jeanneau";
						})}
						ref={domNodeJeanneau}
						onMouseLeave={() => hoverJeanneau(false)}
					/>
					<DropdownMenu
						closeNavbar={setExpanded}
						close={toggleExcess}
						state={isOpenExcess}
						content={dropdownData.filter((yacht) => {
							return yacht.brand != null && yacht.brand.slug === "excess";
						})}
						ref={domNodeExcess}
						onMouseLeave={() => hoverExcess(false)}
					/>
				</React.Fragment>
			: null }
		</>
  );
};

export default NavBar;
