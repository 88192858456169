import { gql } from "graphql-request";

export const GET_LATEST_NEWS = gql`
  query Articles($locale: String) {
    articles(locale: $locale, limit: 6, sort: "published_at:desc") {
      published_at
      slug
      excerpt
      title
      thumbnail {
        url
        alternativeText
      }
      content
    }
  }
`;

export const GET_NEWS = gql`
  query Articles($locale: String, $start: Int) {
    articles(
      locale: $locale
      limit: 12
      start: $start
      sort: "published_at:desc"
    ) {
      id
      published_at
      slug
      excerpt
      title
      thumbnail {
        url
        alternativeText
      }
      content
    }
  }
`;

export const GET_ARTICLE = gql`
  query Article($slug: String, $locale: String) {
    articles(locale: $locale, where: { slug: $slug }) {
      slug
      published_at
      excerpt
      title
      content
      thumbnail {
        url
      }
    }
  }
`;

export const GET_SEARCHBAR_DATA = gql`
  query SearchbarData($locale: String) {
    yachts(locale: $locale) {
      brand {
        brand
        slug
      }
      model {
        model
        slug
      }
      size {
        size
        slug
      }
      thumbnail {
        url
        alternativeText
        formats
      }
      status
      slug
      title
    }
  }
`;

export const GET_TAGS = gql`
  query Tags($locale: String) {
    tags(locale: $locale) {
      name
      slug
    }
  }
`

export const GET_BRAND_IMAGE = gql`
  query BrandImage($brandSlug: String) {
    brands(where: { slug_contains: $brandSlug }) {
      slug
      Background_Media {
        url
      }
    }
  }
`;

export const GET_LATEST_YACHTS = gql`
  query LatestYachts($locale: String) {
    yachts(locale: $locale, limit: 5, sort: "created_at:desc") {
      slug
      title
    }
  }
`;

export const GET_MENU_YACHTS = gql`
  query MenuYachts($locale: String) {
    yachts(locale: $locale, sort: "model:asc,size:desc") {
      slug
      brand {
        slug
        logo {
          url
          alternativeText
          formats
        }
      }
      isYachtNew
      model {
        model
        imageModel {
          url
          alternativeText
          formats
        }
      }
      size {
        size
      }
    }
  }
`;

export const GET_YACHTS = gql`
  query Yachts($start: Int, $brandSlug: String, $modelSlug: String, $sizeSlug: String, $tagsSlug: [String], $locale: String) {
    yachts(
      locale: $locale,
      start: $start,
      sort: "priority:asc",
      where: {
        brand: { slug_contains: $brandSlug }
        model: { slug_contains: $modelSlug }
        size: { slug_contains: $sizeSlug }
        tags: { slug_in: $tagsSlug}
      }
    ) {
      brand {
        brand
        slug
      }
      model {
        model
        slug
      }
      size {
        size
        slug
      }
      slug
      thumbnail {
        url
        alternativeText
      }
      isYachtNew
      status
    }
  }
`;

export const GET_YACHT = gql`
  query Yacht($slug: String, $locale: String) {
    yachts(locale: $locale, where: { slug: $slug }) {
      slug
      title
      model{
        model
      }
      thumbnail {
        url
      }
      poster {
        url
        alternativeText
      }
      description
      specifications {
        draft
        maxSpeed
        yacthLength
        engineModel
        model
        make
        fuelCapacity
        fuelType
        hullMaterial
        passengers
        masterBedroom
        grossWeight
      }
      specification{
        value
        value_es
        feature{
          name
          name_es
          large
          icon{
            url
          }
        }
      }
      feature {
        name
      }
      url3DView
      exterior {
        url
        alternativeText
      }
      interior {
        url
        alternativeText
      }
      layout {
        url
        alternativeText
      }
      levels{
        name
        name_es
        layout{
          info
          info_es
          imagen{
            url
          }
        }
      }
      youtubeURL
    }
  }
`;

export const GET_HOME_SECTIONS = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      hero {
        Carousel {
          Title
          Description
          Button_Text
          Button_URL
          Background_Image {
            url
            formats
          }
          Background_Video {
            url
          }
        }
      }
      about {
        title
        description
        featuredImage {
          width
          height
          url
          alternativeText
          formats
        }
        card {
          title
          shortDescription
          url
          titleStyle
          shortDescriptionStyle
        }
        option {
          name
          nameStyle
        }
        titleStyle
        descriptionStyle
      }
      cta {
        title
        description
        backgroundImage {
          url
          alternativeText
          formats
        }
        titleStyle
      }
      brands {
        title
        description
        brandPage {
          brand
        }
        logo {
          url
          alternativeText
          formats
        }
        featuredImage {
          width
          height
          url
          alternativeText
          formats
        }
        link
        titleStyle
        descriptionStyle
        titleAlign
        descriptionAlign
      }
      partners {
        title
        partner {
          logo {
            url
            alternativeText
            formats
          }
        }
        titleStyle
      }
      blog {
        title
        news {
          article {
            published_at
            slug
            excerpt
            title
            thumbnail {
              url
              alternativeText
              formats
            }
          }
        }
        titleStyle
      }
      faq {
        title
        pair {
          question
          answer
        }
        titleStyle
        questionStyle
        answerStyle
      }
    }
  }
`;

export const GET_ABOUT_PAGE = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      about {
        title
        description
        featuredImage {
          width
          height
          url
          alternativeText
        }
        card {
          title
          shortDescription
          url
        }
        option {
          name
        }
      }
    }
  }
`;

export const GET_CONTACT_PAGE = gql`
  query Contactpage($locale: String) {
    contactpage(locale: $locale) {
      title
      description
      contact {
        name
        email
        message
        button
      }
      map {
        latitude
        longitude
        zoom_level
        locations {
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_FOOTER = gql`
  query Footerpage($locale: String) {
    footer(locale: $locale) {
      description
      address
      address_link
      email
      phone
      facebook
      twitter
      instagram
      linkedin
      whatsapp
      descriptionStyle
      addressStyle
      emailStyle
      phoneStyle
    }
  }
`;

export const GET_PRIVACY_PAGE = gql`
  query Privacypage($locale: String) {
    privacyPolicy(locale: $locale) {
      title
      content
    }
  }
`;

export const GET_TERMS_PAGE = gql`
  query Termsypage($locale: String) {
    termsCondition(locale: $locale) {
      title
      content
    }
  }
`;




// Yay! Studio - MODULAR QUERIES OCT, 2022
export const GET_HOME_BANNERS = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      hero {
        extraImage{
          url
        }
        Carousel {
          Title
          Description
          Button_Text
          Button_URL
          Background_Image {
            url
          }
          Background_Video {
            url
          }
        }
      }
    }
  }
`;

export const GET_HOME_NEWSLETTER = gql`
query Homepage($locale: String) {
  homepage(locale: $locale) {
    newsletter {
      form
      description
    }
  }
}
`;

export const GET_HOME_CTA = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      cta {
        title
        description
        backgroundImage {
          url
          alternativeText
          formats
        }
        titleStyle
      }
    }
  }
`;

export const GET_HOME_BRANDS = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      brands {
        title
        description
        brandPage {
          brand
        }
        logo {
          url
          alternativeText
          formats
        }
        featuredImage {
          width
          height
          url
          alternativeText
          formats
        }
        link
        titleStyle
        descriptionStyle
        titleAlign
        descriptionAlign
      }
    }
  }
`;


export const GET_HOME_PARTNERS = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      partners {
        title
        partner {
          logo {
            url
            alternativeText
            formats
          }
        }
        titleStyle
      }
    }
  }
`;

export const GET_HOME_BLOG = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      blog {
        title
        news {
          article {
            published_at
            slug
            excerpt
            title
            thumbnail {
              url
              alternativeText
              formats
            }
          }
        }
        titleStyle
      }
    }
  }
`;

export const GET_HOME_FAQ = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      faq {
        title
        pair {
          question
          answer
        }
        titleStyle
        questionStyle
        answerStyle
      }
    }
  }
`;

export const GET_NEWSLETTER = gql`
  query Homepage($locale: String) {
    homepage(locale: $locale) {
      newsletter {
        form
      }
    }
  }
`;

export const GET_YACHT_CONTACT = gql`
  query YachtContact($locale: String) {
    yachtContact(locale: $locale) {
      text
    }
  }
`;

