import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as fbq from '../lib/fpixel'


import { getBlurredImage } from "../lib/imageOptimization";

export const DropdownMenu = ({ close, closeNavbar, content, state, onMouseLeave }) => {
  const [ filteredData, setFilteredData ] = useState([]);


  useEffect(() => {
    const uniqueNames = [...new Set(content.map(item => item.model.model))];
    const uniqueImages = [...new Set(content.map(item => item.model.imageModel.url))];
    
    const groupedData = [];
    uniqueNames.map((name, index) => {
      groupedData.push({
        model: name,
        image: uniqueImages[index],
        yachts: content.filter((yacht) => {
          return yacht.model.model === name;
        })
      });
    });
  
    // Función para búsqueda binaria
    const indexFLine = binarySearch(groupedData, "F-LINE");
    const indexSLine = binarySearch(groupedData, "S-LINE");
    
    // Intercambiar F-LINE y S-LINE si existen
    if (!isNaN(indexFLine) && !isNaN(indexSLine)) {
      let clipboard = groupedData[indexFLine];
      groupedData[indexFLine] = groupedData[indexSLine];
      groupedData[indexSLine] = clipboard;
    }
  
    // Orden personalizado para los modelos especificados
    const sortOrder = ['LEADER WA', 'NC WEEKENDER', 'DB', 'LEADER CC', 'NC SPORT'];
  
    // Reordenar los datos según el orden personalizado
    const sortedGroupedData = groupedData.sort((a, b) => {
      const upperA = a.model.toUpperCase();
      const upperB = b.model.toUpperCase();
      
      const indexA = sortOrder.indexOf(upperA);
      const indexB = sortOrder.indexOf(upperB);
  
      // Si ambos modelos están en sortOrder, comparar por su posición en el array
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }
      // Si solo uno de ellos está en sortOrder, priorizar el que sí está
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;
      // Si ninguno está en sortOrder, mantener el orden original
      return 0;
    });
  
    setFilteredData(sortedGroupedData);
  
  }, [content]);
  

  return (
    <Container
      onMouseLeave={onMouseLeave}
      className={`dropdown-yachts-container ${state ? "d-block" : "d-none"}`}
    >
      <div
        className={`dropdown-yachts px-5 justify-content-center ${
          state ? "animmateIn" : "animmateOut"
        }`}
      >

      <div className="model-list">
        <div className="close-container px-0 d-lg-none">
          <div className="close ms-auto" onClick={() => close()}>
            <span></span>
            <span></span>
          </div>
        </div>
        
        {filteredData.map((group, index) => {
          return (
            <Col
              key={index}
              
              className="d-flex flex-column mb-4 mb-lg-0"
            >
              <Image
                src={group.image}
                alt="Model Image"
                layout="fixed"
                loading='lazy'
                width="161"
                height="68"
                objectFit="contain"
                placeholder="blur"
                blurDataURL={getBlurredImage(group.image)}
              />

              <span className="mt-3 mb-4 text-uppercase">
                {group.model}
              </span>

              <div className="d-flex flex-wrap">
                {group.yachts.sort((a, b) => {
                  // Compare sizes as strings (a 0 must be added at the beginning to sizes with a charachter less)
                  const sizeA = a.size.size.split(" ")[0];
                  const sizeB = b.size.size.split(" ")[0];
                  if (parseFloat(sizeA) < parseFloat(sizeB)) {
                    return -1;
                  }
                  if (parseFloat(sizeA) > parseFloat(sizeB)) {
                    return 1;
                  }
                
                  return 0;
                }).map((yacht, index) => {
                    return (
                      <Link
                        key={index}
                        href={`/yachts/${yacht.slug}`}
                        scroll={true}
                        className="btn btn-outline-primary me-2 mb-2 py-0 px-1 px-xl-2" 
                        onClick={() => {close(); closeNavbar(false); fbq.event('ViewContent',{content_ids: group.mode +  yacht.size?.size[0] === "0" ? yacht.size?.size.substring(1) : yacht.size?.size})}}
                      > 
                        {yacht.size?.size[0] === "0" ? yacht.size?.size.substring(1) : yacht.size?.size} 
                        <sup>{yacht.isYachtNew ? 'NEW': ''}</sup>
                      </Link>
                    );
                })}
              </div>
            </Col>
          );
        })}
        </div>
      </div>
    </Container>
  );
};

const binarySearch = (arr, x) => {
  let start = 0;
  let end = arr.length - 1;
       
  while (start <= end){
    let mid=Math.floor((start + end)/2);

    if (arr[mid].model === x)
      return mid;
    else if (arr[mid] < x)
      start = mid + 1;
    else
      end = mid - 1;
  }

  return false;
}

DropdownMenu.displayName = "DropdownMenu";
