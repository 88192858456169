import { setConfig, buildUrl } from 'cloudinary-build-url'

setConfig({
  cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_NAME
})

export const getBlurredImage = (url) =>  {
  return buildUrl(url, {
    transformations: {
      effect: "blur:1000",
      quality: 1
    }
  }
)};
