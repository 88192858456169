// Yay! Estudio
import { request } from "graphql-request"
import * as Queries from "./queries";
/*
    Call Grapql/Strapi only if response doesn't exists already in localStorage
    or have passed more than 10 minutes since last call:
*/
export const cachedRequest = (query, params) => {
    return new Promise((resolve, reject) => {
        let minutes = 10;
        let localData = (localStorage.getItem(query)) ? JSON.parse(localStorage.getItem(query)) : false;
        let diffInMin = (localData?.timestamp) ? Math.floor((Date.now() - localData.timestamp) / 60000) :  false;

        if(!localData || diffInMin > minutes){
            console.log('Get from REMOTE ==> ', query);
            request(
                process.env.NEXT_PUBLIC_STRAPI_GRAPHQL_ENDPOINT,
                Queries[query],
                params,
            ).then((res) => {
                localStorage.setItem(query, JSON.stringify({
                    timestamp: Date.now(),
                    data: res
                }));
                resolve(res);
            });
        } else {
            // Get data from localStorage and avoid a query:
            console.log('Get from LOCAL ==> ', query);
            resolve(localData.data);
        }
    });
}