import { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import Dropdown from 'react-bootstrap/Dropdown';

import { Globe } from "./Icons";

const LanguageSwitch = ({transparent}) => {

  const router = useRouter();
  const [show, setShow] = useState(false);
  const [localizedPath, setLocalizedPath] = useState("");
  const [hoverState, setHoverState] = useState(false)

  useEffect(() => {
    if (router.asPath.includes("/news/") || router.asPath.includes("/yachts/")) {
      router.locale === "en"
        ? setLocalizedPath(router.asPath + "-es")
        : setLocalizedPath(router.asPath.slice(0, -3))
    }else{
      setLocalizedPath(router.asPath)
      
    }
  }, [router])

  return (
    <Dropdown onMouseEnter={()=>{setHoverState(true)}} onMouseLeave={()=>{setHoverState(false)}}  show={show}>
      <Dropdown.Toggle
        variant="outline-primary"
        className={"switch-button " + (transparent && "transparent")}
        id="language-switch"
        onClick={() => setShow(!show)}
      >
        <Globe color={hoverState ?  "white" : transparent ? "white" : "#111D3B"} />
        {router.locale === "es" ? "Es" : "En"}
      </Dropdown.Toggle>

      <Dropdown.Menu >
        <Link
          href={localizedPath}
          locale={router.locale === "en" ? "es" : "en"}
          className="dropdown-item" onClick={() => setShow(!show)}
        >
          {router.locale === "en" ? "Es" : "En"}
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitch;
